@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;600&display=swap");
@import url("https://fonts.cdnfonts.com/css/oregon-ldo");

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font-size: 16px;
}

body {
  font-family: "Poppins", sans-serif;
  line-height: 1.6;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
ol,
li,
figure,
img,
svg {
  margin: 0;
  padding: 0;
}

ul,
ol {
  list-style: none;
}

a {
  text-decoration: none;
}

img,
svg {
  max-width: 100%;
  height: auto;
  display: block;
}

body {
  background: linear-gradient(180deg, #070707 -36.28%, #efefef 131.64%);
  width: 100%;
}

.page {
  z-index: 3;
  height: 100vh;

  display: flex;

  flex-direction: column;

  justify-content: space-between;
  position: relative;

  overflow: hidden;
}

.background-logo {
  position: absolute;
  bottom: -10%;
  left: 0%;

  width: 100vw;
  z-index: 2;
}

@media only screen and (max-width: 600px) {
  .background-logo {
    width: 150vw;
  }
}

.logo {
  display: flex;
  flex-direction: column;

  width: 150px;

  align-items: center;
  justify-content: center;

  gap: 12px;

  padding: 32px;
}

.logo p {
  margin: 0;
}

.hero {
  display: flex;
  flex-direction: column;
  gap: 128px;
}

.hero .hero-title {
  display: flex;
  flex-direction: column;
  align-items: center;

  gap: 24px;
}

#name {
  color: #efefef;
  text-align: center;
  font-family: "Oregon LDO", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.1vw;
}

.title {
  padding-top: 8%;
  color: #020202;
  text-align: center;
  text-shadow: 0px 4px 6px rgba(2, 2, 2, 0.19);
  font-family: "Oregon LDO", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 2.8em; /* Adjust as needed */

  line-height: normal;
  letter-spacing: 24px;

  filter: drop-shadow(-12, 0, 44, #000);
  display: flex;

  margin-right: -10px;
}

.subtitle {
  color: #020202;
  text-align: center;
  font-family: Poppins;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}

@media only screen and (max-width: 600px) {
  .title {
    font-size: 1.5em; /* Adjust as needed */
    letter-spacing: 12px;
  }
  .subtitle {
    font-size: 1.1em; /* Adjust as needed */
  }
}

.form {
  padding-bottom: 10%;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.form p {
  color: #020202;
  text-align: center;
  font-family: Poppins;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}

.email {
  width: 50vw;
  max-width: 700px;
  height: 3vw;
  border-radius: 32px;

  padding-left: 12px;
  border: 0.2vw solid #020202;
  background: rgba(187, 187, 187, 1);
  color: #000000;
  text-align: left;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  line-height: normal;

  padding: 24px;
}

.email::placeholder {
  color: black;
}

.submit-button {
  background: #020202;
  color: #bbb;
  text-align: center;
  font-family: Poppins;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

  padding: 12px 32px;
  border: none;
  border-radius: 32px;
}

.contact {
  display: flex;
  flex-direction: column;
  align-items: center;
}



.contact form {
  display: flex;
  flex-direction: row;
  gap: 12px;
}

@media only screen and (max-width: 600px) {

.email {
    width: 80vw;
}
.submit-button {
    width: 30vw;
}
  .contact form {
    flex-direction: column;
    align-items: center;
  }
}

.form input {
  margin-right: 1%;
}

.social-media {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 32px;
}

.social-media img {
  margin-right: 1.5vw;
  top: 2vw;
  left: 2vw;
}
